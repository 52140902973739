export const VENUE_SIZES = ['1','2','3','4','5','6','7','8','9','10','20','50','100','500'];
export const VENUE_INTERVAL = [
  {'_id': '6', 'value': '6 Months', 'convertedVal': 6},
  {'_id': '1', 'value': '1 Year', 'convertedVal': 12},
  {'_id': '2', 'value': '2 Year', 'convertedVal': 24},
  {'_id': '3', 'value': '3 Year', 'convertedVal': 36}
];
export const STATUS = {
  1: 'Active',
  2: 'Inactive'
}