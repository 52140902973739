import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import {AuthGuard} from 'src/app/_guard/auth.guard';

import {LoginComponent} from './login/login.component';
import {DashboardComponent} from './dashboard/dashboard.component';
import {ChangePasswordComponent} from './change-password/change-password.component';
import {HomeComponent} from './home/home.component';
import {ForgotPasswordComponent} from './forgot-password/forgot-password.component';
import {VerifyOtpComponent} from './verify-otp/verify-otp.component';
import {WebFormComponent} from './web-form/web-form.component';
import {ContactUsComponent} from './contact-us/contact-us.component';

import {EmailsListComponent} from './emails-list/emails-list.component'
import { AccountDetailsComponent } from './account-details/account-details.component';
import { EditAccountDetailsComponent } from './edit-account-details/edit-account-details.component';
import { UsersComponent } from './users/users.component';
import {UserDetailComponent} from './user-detail/user-detail.component';
import { ReportUserComponent } from './report-user/report-user.component';
import {ReportListComponent} from './report-list/report-list.component';
import { VenuesComponent } from './venues/venues.component';
import { VenueDetailComponent } from './venue-detail/venue-detail.component';
import { VenuesSubscriptionComponent } from './venues-subscription/venues-subscription.component';
import { AddVenueSubscriptionComponent } from './venues-subscription/add-venue-subscription.component';
import { EditVenueSubscriptionComponent } from './venues-subscription/edit-venue-subscription.component';
const routes: Routes = [
  {
    path:"",
    // pathMatch: 'full',
    component:DashboardComponent,
    canActivate: [AuthGuard],
    children:[
      {path: '', component: HomeComponent},
			{path: 'dashboard', component: HomeComponent},
      {path:"change-password",component:ChangePasswordComponent
      },
      {path:"my-account",component:AccountDetailsComponent
      },
      {path:"edit-detail",component:EditAccountDetailsComponent
      },
      {path:"contactus-listing", component:ContactUsComponent},
      {path:"reported-users", component:ReportUserComponent},
      {path:"report-list/:userId",component:ReportListComponent},
      {path:"email-list", component:EmailsListComponent},
      {
        path:"users",
        component:UsersComponent
      },
      {
        path:"user/:id",
        component:UserDetailComponent
      },
      {
        path:"venues",
        component:VenuesComponent
      },
      {
        path:"venue/:id",
        component:VenueDetailComponent
      },
      {
        path:"venue/subscription/list",
        component: VenuesSubscriptionComponent
      },
      {
        path:"venue/subscription/add",
        component: AddVenueSubscriptionComponent
      },
      {
        path:"venue/subscription/view/:id",
        component: EditVenueSubscriptionComponent
      }
    ]

  },
  {
    path:'login',
    pathMatch: 'full',
    component:LoginComponent
  },
  {
    path:"forgot-password",
    component:ForgotPasswordComponent
  },
  {
    path:"otp-verify",
    component:VerifyOtpComponent
  },
  {
    path:"web/:userId",
    component:WebFormComponent,
    pathMatch: 'full'
  },  
  {
    path:"**",
    component:LoginComponent    
  }

  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
