import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup, FormBuilder, Validators} from '@angular/forms';
import {Router, ActivatedRoute} from '@angular/router';

import { AuthService } from '../_services/auth.service';

import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  webTitle="Login";
  loginForm:FormGroup;
  errorVal:string;
  returnUrl: string;
  isLoading:Boolean=false;


  constructor(private fb: FormBuilder,
    private toastr: ToastrService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router
    ) { }

  

  ngOnInit(): void {
    this.authService.clearLocalStorage();
    // this.authService.logout();
    this.loginForm= this.fb.group({
      email:["", [Validators.required, Validators.email]],
      password:["", [Validators.required]]
    })

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/dashboard';
    // alert(this.returnUrl);
    
    if(localStorage.getItem('currentUser')){
      this.router.navigate([this.returnUrl]);
    }
    
  }

  onSubmit(){
    // TODO: Use EventEmitter with form value
    
    this.errorVal="";
    this.isLoading=true;
    if (this.loginForm.invalid) {
      this.toastr.error('All fields are required');
    } 
    else{
      this.authService.login(this.f.email.value, this.f.password.value)
      .subscribe(res => {  
        // console.log(data);      
        this.authService.setToken(res.data.token);
        // store username and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('currentUser', JSON.stringify({ email:res.data.email ,  name:res.data.name,
        userId:res.data.userId}));

        this.router.navigate(['/dashboard']);
        this.isLoading=false;
      },
      err => {        
        this.errorVal = err.error.message;
        this.isLoading=false;     
        this.toastr.error(this.errorVal);
        throw err;
      }
      );
    }
  }

  // convenience getter for easy access to form fields
  get f() { return this.loginForm.controls; }

}
