import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import {WebService} from '../_services/web.service';
import {Subject} from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import {DataTableDirective} from 'angular-datatables';
declare var $;

@Component({
  selector: 'app-report-user',
  templateUrl: './report-user.component.html',
  styleUrls: ['./report-user.component.css']
})
export class ReportUserComponent implements OnInit,AfterViewInit {
  // @ViewChild('dataTable',{static:false}) table;
  // dataTable:any;
  dtOptions: DataTables.Settings = {};
  dtElement:DataTableDirective;
  dtTrigger: Subject<any> = new Subject<any>();
  users:Array<any>=[];
  isLoading:boolean=true;
  showTable:boolean=false;


  constructor(private webService: WebService,private toastr: ToastrService,) { }

  ngOnInit(): void {
      this.dtOptions = {
        pagingType: 'full_numbers',            
        processing: true,
        // destroy: true,
        ordering: true,
        columnDefs: [{ 
          targets: 0          
        }]
      };

    this.webService.report_users().subscribe(res=>{
      console.log(res);
      this.users=res.data;
      this.dtTrigger.next();
      this.showTable=true;
      
      this.isLoading=false;   
      
    },err=>{
      this.isLoading=false;
      this.toastr.error(err.message);      
    })
  }

  

  changeStatus(user_id,status){
    let updatedStatus=status?false:true;
    this.webService.updateStatus(user_id,updatedStatus).subscribe(res=>{
      setTimeout(()=>{        
        location.reload();        
      },1000);      
      this.toastr.success(res.message);
    },err =>{
      // console.log(err);
      this.toastr.error(err.message);
    })
    
  }
  ngAfterViewInit():void{
    // this.dtTrigger.next();
  }
}
