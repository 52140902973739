import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { VenueSubscriptionService } from '../_services/venue-subscription.service';
import { VENUE_SIZES, VENUE_INTERVAL } from '../_constants/venue.constants';
import { Interval } from '../_models/venue-subscription.model';
@Component({
  selector: 'app-add-venue-subscription',
  templateUrl: './add-venue-subscription.component.html',
  styleUrls: ['./add-venue-subscription.component.css']
})
export class AddVenueSubscriptionComponent implements OnInit {
  venueSizes: String[] =  VENUE_SIZES;
  venueIntervals: Interval[] = VENUE_INTERVAL;
  subscriptionForm: FormGroup;
  constructor(
    private fb: FormBuilder,
    private venueSubscrService: VenueSubscriptionService,
    private toastr: ToastrService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.subscriptionForm = this.fb.group({
      name: ['', Validators.required],
      description: ['', Validators.required],
      size: [null, Validators.required],
      amount: [null, Validators.required],
      interval: [null, Validators.required]
    });
  }

  onAddSubscription() {
    let data = this.subscriptionForm.value;
    this.venueSubscrService.createSubscription(data).subscribe(res => {
      this.toastr.success(res.message);
      this.router.navigate(['/venue/subscription/list'])
    },
    err => {
      const errMsg = (err.name == "HttpErrorResponse") ? err.error.message : err.message
      this.toastr.error(errMsg);
    });
  }

}
