<body class="bg-gradient-primary">
    <div id="overlay" *ngIf="isLoading">
        <div class="spinner-border align-items-center text-light"></div>
    </div>
    <div class="container">

        <!-- Outer Row -->
        <div class="row justify-content-center">

            <div class="col-md-12 text-center pt-5">
                <!-- <img src="http://manegeek.com/image/logo-clr.png"> -->
            </div>
            <div class="col-md-6">

                <div class="card o-hidden border-0 shadow-lg my-5">
                    <div class="card-body p-0">
                        <!-- Nested Row within Card Body -->
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="p-5">
                                    <div class="text-center">
                                        <h1 class="h4 text-gray-900 mb-2">Forgot Your Password?</h1>
                                        <p class="mb-4">We get it, stuff happens. Just enter your phone number below and we'll send you a OTP to reset your password!</p>
                                    </div>
                                    <form class="user" id="email" [formGroup]="forgotPasswordForm" (ngSubmit)="onSubmit(forgotPasswordForm.value)">
                                        <div class="form-group">
                                            <input type="email" formControlName="phone_number" class="form-control form-control-user" aria-describedby="emailHelp" placeholder="phone number">
                                            <div *ngIf="submitted && f.phone_number.errors && errorValue != '' " class="invalid-feedback">
                                                <div *ngIf="f.phone_number.errors.required">Phone number is required</div>

                                            </div>
                                        </div>

                                        <div class="form-group">
                                            <div *ngIf="errorValue" class="form-group col-md-12">
                                                <div class="invalid-feedback">
                                                    <div *ngIf="errorValue">{{ errorValue }}</div>
                                                </div>
                                            </div>
                                            <div *ngIf="successVal" class="form-group col-md-12">
                                                <div class="alert-success">
                                                    <div *ngIf="successVal">{{ successVal }}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <input type="submit" class="btn btn-primary btn-user btn-block" name="Submit" [disabled]="!forgotPasswordForm.valid">
                                        </div>
                                    </form>
                                    <hr>
                                    <div class="text-center">
                                        <a class="small" routerLink="/login">Already have an account? Login!</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div class="col-md-12 text-center pt-5">
                <!-- <img src="http://manegeek.com/image/logo-clr.png"> -->
            </div>

        </div>

    </div>
    <!-- Add footer -->
    <app-footer></app-footer>

</body>