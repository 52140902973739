// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrl : 'https://api.gosalworks.com/v1/api',
  // apiUrl:"http://112.196.26.154:3800/v1/api",
  BUCKET_NAME:"dev-radarqr-images-original",
  VENUE_IMAGE_BUCKET_ORIGINAL:"dev-radarqr-venues-images-original",
  IDENTITY_POOL_ID:"us-west-2:d7459fb4-fe0f-4e56-b397-8c138f8cb4dc",
  REGION:"us-west-2",
  userProfile:"https://profile.gosalworks.com/user",
  qrCodeBaseUrl:"https://profile.gosalworks.com/user"
  
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
