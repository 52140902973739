<body class="bg-gradient-primary">
    <div id="overlay" *ngIf="isLoading">
        <div class="spinner-border align-items-center text-light"></div>
    </div>
    <div class="container">
        <!-- Outer Row -->
        <div class="row justify-content-center">

            <div class="col-md-12 text-center pt-5">
            </div>
            <div class="col-md-6">

                <div class="card o-hidden border-0 shadow-lg my-5">
                    <div class="card-body p-0">
                        <!-- Nested Row within Card Body -->
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="p-5">
                                    <div class="text-center">
                                        <h1 class="h4 text-gray-900 mb-2">Verify OTP</h1>

                                    </div>
                                    <form class="user" id="email" [formGroup]="verifyOTPForm" (ngSubmit)="onSubmit()">
                                        <div class="form-group">
                                            <input type="text" formControlName="otp" class="form-control form-control-user" aria-describedby="emailHelp" placeholder="OTP " #otp (keyup)="verifyOTP($event)">
                                            <div *ngIf="f.otp.errors " class="invalid-feedback">
                                                <div *ngIf="f.otp.errors.required">OTP is required
                                                </div>

                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <input type="password" formControlName="password" [readonly]="isEditable" class="form-control form-control-user" placeholder="Password">
                                            <div *ngIf="f.password.invalid && (f.password.dirty || f.password.touched)" class="alert alert-danger">
                                                <div *ngIf="f.password.errors.required">passwod is required.</div>
                                                <div *ngIf="f.password.errors.minlength">Password must be at least 6 characters</div>

                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <input type="password" formControlName="confirm_password" [readonly]="isEditable" class="form-control form-control-user" placeholder="Repeat password">
                                        </div>
                                        <div *ngIf="f.confirm_password.invalid && (f.confirm_password.dirty || f.confirm_password.touched)" class="alert alert-danger">
                                            <div *ngIf="f.confirm_password.errors.required">Confirm Password is required</div>

                                            <div *ngIf="f.confirm_password.errors.mustMatch">Passwords must match</div>
                                        </div>

                                        <div class="form-group">
                                            <input type="submit" class="btn btn-primary btn-user btn-block" name="Submit">
                                        </div>
                                    </form>
                                    <hr>
                                    <div class="text-center">
                                        <a class="small" routerLink="/login">Already have an account? Login!</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div class="col-md-12 text-center pt-5">
                <!-- <img src="http://manegeek.com/image/logo-clr.png"> -->
            </div>

        </div>

    </div>
    <!-- Add footer -->
    <app-footer></app-footer>

</body>