<!-- Begin Page Content -->
<div class="container-fluid">

    <!-- DataTales Example -->
    <div class="card shadow mb-4">
        <div class="card-header d-sm-flex align-items-center justify-content-between py-3">
            <h1 class="h3 mb-0 text-gray-800">Venues</h1>
        </div>
        <div class="card-body">
            <div class="table-responsive">
                <table datatable [dtOptions]="dtOptions" class="table table-bordered hover" width="100%"
                    cellspacing="0">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Uniquename</th>
                            <th>Description</th>
                            <th>Type</th>
                            <th>Contact Info</th>
                            <th>Created By</th>
                            <th>Email</th>
                            <th>Creation Date</th>
                            <th>Status</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody *ngIf="venues?.length != 0">
                        <tr *ngFor="let venue of venues">
                            <td class="name">{{ (venue.name !== '') ? venue.name : 'N/A' }}</td>
                            <td class="uniquename">{{ (venue.uniquename !== '') ? venue.uniquename : 'N/A'}} </td>
                            <td class="description">{{ (venue.description !== '') ? venue.description : 'N/A' }}</td>
                            <td class="type">{{ venue.type?.name || 'N/A' }}</td>
                            <td>{{ venue.contactinfo?.name || 'N/A' }} </td>
                            <td>{{venue.user_info?.name | titlecase}}</td>
                            <td>{{venue.user_info?.email}}</td>
                            <td>{{venue.createdAt | date:'short'}}</td>
                            <td>{{venue.statusname}}</td>
                            <td style="width: 15%;">
                                <a routerLink="/venue/{{ venue._id }}" class="btn btn-primary btn-sm">View</a>
                            </td>
                        </tr>
                    </tbody>
                    <tbody *ngIf="venues?.length == 0">
                        <tr>
                            <td colspan="3" class="no-data-available">No records found!</td>
                        </tr>
                    <tbody>
                </table>
            </div>
        </div>
    </div>

</div>
<!-- /.container-fluid -->