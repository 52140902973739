import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

  resetPasswordForm:FormGroup
  constructor(
    private fb:FormBuilder,
    private toastr:ToastrService
  ) { }

  ngOnInit(): void {
    this.resetPasswordForm=this.fb.group({
      password:["",Validators.required],
      confirm_password:["", Validators.required]
    })
  }

  onSubmit(){
    if(this.resetPasswordForm.invalid){
      this.toastr.error("All fields are required");
      return;
    }
  }


  get f(){
    return this.resetPasswordForm.controls;
  }

}
