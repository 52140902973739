import { Component, OnInit } from '@angular/core';

import {UsersService} from '../_services/users.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  activeUsersCount:number=0
  spamUsersCount:number=0
  inactiveUsers:number=0
  
  constructor(private userService: UsersService) { }
  
  ngOnInit(): void {
    this.userService.countData().subscribe(res=>{
      console.log(res);
      this.activeUsersCount = res['data'].active_users_count; 
      this.inactiveUsers = res['data'].inactive_users_count;     
      this.spamUsersCount = res['data'].spam_users_count;
    }, err=>{
      console.log(err);
    })
  }

}
