import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, } from '@angular/forms';

import {ToastrService} from 'ngx-toastr';
import {AuthService} from '../_services/auth.service';
import { PassValueService } from '../_services/pass-value.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-verify-otp',
  templateUrl: './verify-otp.component.html',
  styleUrls: ['./verify-otp.component.css']
})
export class VerifyOtpComponent implements OnInit {

  webTitle="Reset password";
  verifyOTPForm:FormGroup;
  isEditable:Boolean;
  phone_number:string
  otpVal:string;
  isLoading:boolean=false

  constructor(
    private fb:FormBuilder,
    private toastr:ToastrService,
    private authService:AuthService,    
    private router: Router
    ) { }

  ngOnInit(): void {   

    this.isEditable=true;
    this.otpVal="";

    this.verifyOTPForm=this.fb.group({
      otp:["", [Validators.required, Validators.maxLength(4),Validators.minLength(4)]],
      password:["", [Validators.required,Validators.minLength(6), Validators.maxLength(25)]],
      confirm_password:["",[Validators.required, Validators.minLength(6), Validators.maxLength(25)]]
    }, { validator: this.checkIfMatchingPasswords('password', 'confirm_password') });
  }

  verifyOTP(event:KeyboardEvent):void{
    this.otpVal = (event.target as HTMLInputElement).value;
    
    if(this.otpVal.length==4){      
      this.isEditable=false;
      this.isEditable=false;
    }

  }

  onSubmit(){
    this.phone_number="";
    this.isLoading=true;
    if(localStorage.getItem("phoneNumber")){
      this.phone_number=localStorage.getItem("phoneNumber");
    }else{
      this.router.navigate(['/forgot-password']);
    }
    
    if(this.verifyOTPForm.invalid){    
      console.log(this.verifyOTPForm);  
      this.toastr.error("All fields are required");
      this.isLoading=false;
      return;
    }
    this.authService.reset_password(this.phone_number,this.f.otp.value, this.f.password.value, this.f.confirm_password.value).subscribe(res=>{
      this.toastr.success(res.message);
      this.verifyOTPForm.reset();
      this.isLoading=false;
      this.router.navigate(['/login']);
    },err=>{
      this.toastr.error(err.error.message);
      this.isLoading=false;
    })
  }

  get f(){
    return this.verifyOTPForm.controls;
  }

  checkIfMatchingPasswords(passwordKey: string, passwordConfirmationKey: string) {
    return (group: FormGroup) => {
      let passwordInput = group.controls[passwordKey],
          passwordConfirmationInput = group.controls[passwordConfirmationKey];
      if (passwordInput.value !== passwordConfirmationInput.value) {
        return passwordConfirmationInput.setErrors({mustMatch: true})
      } else {
          return passwordConfirmationInput.setErrors(null);
      }
    }
}

}
