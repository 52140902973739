import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import {AuthService} from '../_services/auth.service';
import {PassValueService} from '../_services/pass-value.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

  forgotPasswordForm:FormGroup;
  submitted=false;
  errorValue:string
  successVal:string
  isLoading:boolean=false;


  constructor(private fb:FormBuilder,
    private toastr:ToastrService,
    private authService: AuthService,
    private router:Router,
    private passValues:PassValueService
    ) { }

  ngOnInit(): void {
    this.forgotPasswordForm= this.fb.group({
      phone_number:["", [Validators.required]]
    });
  }

  onSubmit(formValues){

    this.isLoading=true;
    this.submitted=true;
    this.errorValue="";
    this.successVal="";
    if(this.forgotPasswordForm.invalid){
      this.toastr.error("Phone number is required");
      return;
    }

    this.authService.forgot_password(this.f.phone_number.value).
    subscribe(res=>{
      this.passValues.sendMessage(formValues);
      localStorage.setItem("phoneNumber",this.f.phone_number.value);
      this.forgotPasswordForm.reset();
        this.errorValue = '';        
        this.toastr.success(res.message);
        this.forgotPasswordForm.reset();
        this.isLoading=false;
        
        this.router.navigate(['/otp-verify']);
    },err=>{
      this.errorValue = err.error.message;
      this.toastr.error(this.errorValue);
      this.isLoading=false;
    })
  }

  get f() { return this.forgotPasswordForm.controls; }
}
