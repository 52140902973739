<div class="bg-gradient-primary">
    <div id="overlay" *ngIf="isLoading">
        <div class="spinner-border align-items-center text-light"></div>
    </div>
    <div class="container">
        <!-- Outer Row -->
        <div class="row justify-content-center">
            <div class="col-md-12 text-center pt-5">
                <img src="assets/v1/img/logoNew.svg" width="120px" height="50px">
            </div>
            <div class="col-md-6">
                <div class="card o-hidden border-0 shadow-lg my-5">
                    <div class="card-body p-0">
                        <!-- Nested Row within Card Body -->
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="p-5">
                                    <div class="text-center">
                                        <h1 class="h4 text-gray-900 mb-4">Login</h1>
                                    </div>
                                    <form class="user" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                                        <div class="form-group">
                                            <input type="text" id="email" name="email" formControlName="email" class="form-control form-control-user" placeholder="Email" autocomplete="nope">
                                            <div *ngIf="f.email.invalid && (f.email.dirty || f.email.touched)" class="alert alert-danger">
                                                <div *ngIf="f.email.errors.required">Email is required</div>
                                                <div *ngIf="f.email.errors.email">Email is not valid.</div>
                                            </div>

                                        </div>
                                        <div class="form-group">
                                            <input type="password" formControlName="password" class="form-control form-control-user" placeholder="Password">
                                            <div *ngIf="f.password.invalid && (f.password.dirty || f.password.touched)" class="alert alert-danger">
                                                <div *ngIf="f.password.errors.required">Password is required</div>
                                                <div *ngIf="f.password.errors.minglength">Password must be at least 6 characters</div>

                                            </div>

                                        </div>
                                        <div class="form-group">

                                            <button type="submit" class="btn btn-primary btn-user btn-block" [disabled]="!loginForm.valid">Submit</button>
                                        </div>

                                    </form>
                                    <hr>
                                    <div class="text-center">
                                        <a class="small" routerLink="/forgot-password">Forgot Password?</a>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div class="col-md-12 text-center pt-5">
            </div>

        </div>

    </div>

    <app-footer></app-footer>

</div>