import { Component, OnInit } from '@angular/core';
import { FormControl,FormGroup, FormBuilder, Validators } from '@angular/forms';

import {ChangePasswordService} from '../_services/change-password.service';

import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {

  
  changePwdForm:FormGroup;
  errorValue:String;
  isLoading:boolean=false;

  constructor(
    private fb:FormBuilder,
    private toastr:ToastrService,
    private changePasswordService: ChangePasswordService
    ) { }

  ngOnInit(): void {

    this.changePwdForm=this.fb.group({
      old_password:["", Validators.required],
      new_password:["", [Validators.required, Validators.minLength(8), Validators.maxLength(25)]],
      confirm_password:["", [Validators.required, Validators.minLength(8), Validators.maxLength(25)]]
    },{ validator: this.checkIfMatchingPasswords('new_password', 'confirm_password') });
  }
  onSubmit(){
    this.isLoading=true;
    if(this.changePwdForm.invalid){
      this.toastr.error("All fields are required");
      return false;
    }
    this.errorValue='';

    this.changePasswordService.change_password(this.f.old_password.value,this.f.new_password.value).
    subscribe(res=>{
      this.toastr.success(res.message);
      this.changePwdForm.reset();
      this.isLoading=false;
    },
    err=>{      
      this.toastr.error(err.error.message);
      this.isLoading=false;
    })
  }

  get f(){
    return this.changePwdForm.controls;
  }

  checkIfMatchingPasswords(passwordKey: string, passwordConfirmationKey: string) {
    return (group: FormGroup) => {
      let passwordInput = group.controls[passwordKey],
          passwordConfirmationInput = group.controls[passwordConfirmationKey];
      if (passwordInput.value !== passwordConfirmationInput.value) {
        return passwordConfirmationInput.setErrors({notEquivalent: true})
      } else {
          return passwordConfirmationInput.setErrors(null);
      }
    }
}
}
