import { Component, OnInit } from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {WebService} from '../_services/web.service';



class Email {  
  name: string;
  subject: string;
  message: string;
  email: string;  
}

@Component({
  selector: 'app-emails-list',
  templateUrl: './emails-list.component.html',
  styleUrls: ['./emails-list.component.css']
})
export class EmailsListComponent implements OnInit {

  dtOptions: DataTables.Settings = {};
  records: Email[];
  

  constructor(
    private toastr:ToastrService,
    private webService: WebService
    ) { }

  ngOnInit(): void {

    const that = this;
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      serverSide: true,
      processing: true,
      order:[[5, 'desc']],
      ajax: (dataTablesParameters: any, callback) => {
        this.webService.emailListing(dataTablesParameters).subscribe(resp=>{
          
          that.records = resp.data.email_listing;
          
          callback({
            recordsTotal: resp.data.total_count,
            recordsFiltered: resp.data.filteredData,
            data: []
          });           
        },err=>{
          
          this.toastr.error(err.error);
                    callback({
                      processing: false,
                      recordsTotal: 0,
                      recordsFiltered: 0,
                      data: []
                    });
        });
      },
      columns: [{ data: 'name' }, {data: 'email' }, { data: 'phone' }, {data:'owner'},{data:'location'},{data:'createdAt'}],
      "columnDefs": [{ "orderable": false, "targets": [3,4] }]
    };
  }

}
