<body class="bg-gradient-primary">
    <div class="container">
        <!-- Outer Row -->
        <div class="row justify-content-center">

            <div class="col-md-12 text-center pt-5">
                <!-- <img src="http://manegeek.com/image/logo-clr.png"> -->
            </div>
            <div class="col-md-6">

                <div class="card o-hidden border-0 shadow-lg my-5">
                    <div class="card-body p-0">
                        <!-- Nested Row within Card Body -->

                        <div class="row">
                            <div class="col-lg-12">
                                <div class="p-5">
                                    <div class="text-center">
                                        <h1 class="h4 text-gray-900 mb-2">Please enter the details.</h1>

                                    </div>
                                    <form class="user" (ngSubmit)="onSubmit()" [formGroup]="webForm">
                                        <div class="form-group">
                                            <input type="text" class="form-control form-control-user" formControlName="name" placeholder="Name" required>
                                        </div>

                                        <div *ngIf="f.name.invalid && (f.name.dirty || f.name.touched)" class="alert alert-danger">
                                            <div *ngIf="f.name.errors.required">Name is required</div>
                                        </div>


                                        <div class="form-group">
                                            <input class="form-control form-control-user" type="text" placeholder="Email" formControlName="email" required>

                                            <div class="alert alert-danger" *ngIf="f.email.invalid && (f.email.dirty || f.email.touched)">
                                                <div *ngIf="f.email.errors.required">Email is required</div>

                                                <div *ngIf="f.email.errors.email">Email is invalid</div>
                                            </div>
                                        </div>

                                        <div class="form-group">
                                            <input class="form-control form-control-user" type="text" placeholder="Phone Number" formControlName="phone_number" required>

                                            <div class="alert alert-danger" *ngIf="f.phone_number.invalid && (f.phone_number.dirty || f.phone_number.touched)">
                                                <div *ngIf="f.phone_number.required">Phone number is required</div>
                                            </div>
                                        </div>

                                        <div class="form-group">
                                            <textarea class="form-control form-control-user" placeholder="Location" formControlName="location" #location required maxlength="50"></textarea>
                                            <p align="end">{{location.value.length}}/50</p>

                                            <div class="alert alert-danger" *ngIf="f.location.invalid && (f.location.dirty || f.location.touched)">
                                                Location is required

                                            </div>

                                        </div>

                                        <div class="form-group">
                                            <input type="submit" class="btn btn-primary btn-user btn-block" name="Submit">
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div class="col-md-12 text-center pt-5">
                <!-- <img src="http://manegeek.com/image/logo-clr.png"> -->
            </div>

        </div>

    </div>
    <!-- Add footer -->
    <app-footer></app-footer>

</body>