import { Component, OnInit } from '@angular/core';
import { FormGroup,  FormBuilder, Validators } from '@angular/forms';
import {ToastrService} from 'ngx-toastr';

import {AuthService} from '../_services/auth.service';
import { ActivatedRoute } from '@angular/router';
import { UsersService } from '../users-management/_services/users.service';

@Component({
  selector: 'app-web-form',
  templateUrl: './web-form.component.html',
  styleUrls: ['./web-form.component.css']
})
export class WebFormComponent implements OnInit {

  webForm:FormGroup;
  userId:string;
  userDetails:any={};
  

  constructor(
    private fb:FormBuilder,
    private toastr: ToastrService,
    private authService:AuthService,
    private route: ActivatedRoute,
    private userService: UsersService
    ) { }

  ngOnInit(): void {
    this.webForm=this.fb.group({
      name:["", Validators.required],
      email:["",[Validators.required, Validators.email]],
      phone_number: ["", Validators.required],
      location:["",[Validators.required,  Validators.maxLength(50)]]
    });
    
    this.userId= this.route.snapshot.params.userId
    
    if(!this.userId){
      this.webForm.disable();
    }else{
      this.webForm.enable();
    }
  }

  onSubmit(){
    if(this.webForm.invalid){      
      this.toastr.error("All fields are required!!");
      return;
    }
    
    this.authService.webForm(this.f.name.value, this.f.email.value, this.f.phone_number.value, this.f.location.value, this.userId).
    subscribe(res=>{
      this.toastr.success(res.message);
    },
    err=>{
      this.toastr.error(err.error.message);
    });
  }

  get f() {
    return this.webForm.controls;
  }
}
