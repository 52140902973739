<!-- Begin Page Content -->
<div class="container-fluid">

    <!-- DataTales Example -->
    <div class="card shadow mb-4">
        <div class="card-header d-sm-flex align-items-center justify-content-between py-3">
            <h1 class="h3 mb-0 text-gray-800">Subscription Packages</h1>
            <div style="text-align:right;">
                <ng-container>
                    <button (click)="addSubscription()" class="d-none d-sm-inline-block btn btn-sm btn-success shadow-sm me-10">Add New Package</button>
                </ng-container>
            </div>
        </div>
        <div class="card-body">
            <div class="table-responsive">
                <table datatable [dtOptions]="dtOptions" class="table table-bordered hover" width="100%"
                    cellspacing="0">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Description</th>
                            <th>No. of Venues</th>
                            <th>Amount</th>
                            <th>Interval</th>
                            <th>Status</th>
                            <th>Users Opted</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody *ngIf="subscriptions?.length != 0">
                        <tr *ngFor="let subscription of subscriptions">
                            <td class="name">{{ subscription.name }}</td>
                            <td class="description">{{ subscription.description }}</td>
                            <td class="type">{{ subscription.size }}</td>
                            <td>{{subscription.amount|currency }} </td>
                            <td>{{getInterval(subscription.interval)}}</td>
                            <td>{{getStatus(subscription.status)}}</td>
                            <td>{{subscription.users_count}}</td>
                            <td style="width: 15%;">
                                <a routerLink="/venue/subscription/view/{{subscription._id}}" class="btn btn-primary btn-sm">View</a>
                            </td>
                        </tr>
                    </tbody>
                    <tbody *ngIf="subscriptions?.length == 0">
                        <tr>
                            <td colspan="3" class="no-data-available">No records found!</td>
                        </tr>
                    <tbody>
                </table>
            </div>
        </div>
    </div>

</div>
<!-- /.container-fluid -->