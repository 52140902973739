<!-- Sidebar -->
<ul class="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="accordionSidebar">

    <!-- Sidebar - Brand -->
    <a class="sidebar-brand d-flex align-items-center justify-content-center" routerLink="/dashboard">
        <div class="sidebar-brand-icon">
            <img src="assets/v1/img/logoNew.svg" class=" img-response " height="35px " width="80px ">
        </div>
        <!-- <div class="sidebar-brand-text mx-3 ">Mane Geek</div> -->
    </a>

    <!-- Divider -->
    <hr class="sidebar-divider my-0 ">

    <!-- Nav Item - Dashboard -->
    <li class="nav-item ">
        <a class="nav-link " routerLink="/dashboard">
            <i class="fas fa-fw fa-tachometer-alt "></i>
            <span>Dashboard</span></a>
    </li>

    <!-- Divider -->
    <hr class="sidebar-divider ">

    <!-- Heading -->
    <!-- <div class="sidebar-heading ">
      Users
    </div> -->

    <li class="nav-item ">
        <a class="nav-link " routerLink="/users">
            <i class="fas fa-fw fa-users "></i>
            <span>Users</span></a>
    </li>

    <li class="nav-item ">
      <a class="nav-link " routerLink="/reported-users">
          <i class="fas fa-fw fa-users "></i>
          <span>Reported Users</span></a>
    </li>

    <li class="nav-item ">
      <a class="nav-link " routerLink="/venues">
          <i class="fas fa-fw fa-home "></i>
          <span>Venues</span></a>
    </li>

    <li class="nav-item ">
      <a class="nav-link " routerLink="/venue/subscription/list">
          <i class="fas fa-fw fa-credit-card "></i>
          <span>Venue Subscriptions</span></a>
    </li>

    <!-- Nav Item - Pages Collapse Menu -->
    <!-- <li class="nav-item ">
        <a class="nav-link collapsed " href="# " data-toggle="collapse " data-target="#collapseTwo " aria-expanded="true " aria-controls="collapseTwo ">
            <i class="fas fa-fw fa-flag "></i>
            <span>Flagged</span>
        </a>
        <div id="collapseTwo " class="collapse " aria-labelledby="headingTwo " data-parent="#accordionSidebar ">
            <div class="bg-white py-2 collapse-inner rounded ">
                <a class="collapse-item " routerLink="/users/flagged-users ">Flagged Users</a>
                <a class="collapse-item " routerLink="/flagged/posts ">Flagged Posts</a>
                <a class="collapse-item " routerLink="/flagged/comments ">Flagged Comments</a>
            </div>
        </div>
    </li> -->

    <!-- <li class="nav-item ">
        <a class="nav-link " routerLink="/messages/message-listing ">
            <i class="fas fa-fw fa-envelope "></i>
            <span>Ads</span></a>
    </li> -->


    <!-- Nav Item - Charts -->
    <!-- <li class="nav-item ">
      <a class="nav-link collapsed " href="# " data-toggle="collapse " data-target="#collapseClasses " aria-expanded="true " aria-controls="collapseClasses ">
        <i class="fas fa-fw fa-calendar "></i>
        <span>Classes</span></a>
        <div id="collapseClasses " class="collapse " aria-labelledby="headingUtilities " data-parent="#accordionSidebar ">
          <div class="bg-white py-2 collapse-inner rounded ">
            <a class="collapse-item " routerLink="/admin/classes ">All Classes</a>
            <a class="collapse-item " routerLink="/admin/payout-list ">Payout List</a>
            <a class="collapse-item " routerLink="/admin/refund-list ">Refund List</a>
          </div>
        </div>
    </li> -->

    <!-- Nav Item - Utilities Collapse Menu -->
    <!-- <li class="nav-item ">
      <a class="nav-link collapsed " href="# " data-toggle="collapse " data-target="#collapseUtilities " aria-expanded="true " aria-controls="collapseUtilities ">
        <i class="fas fa-fw fa-comments "></i>
        <span>Specialization</span>
      </a>
      <div id="collapseUtilities " class="collapse " aria-labelledby="headingUtilities " data-parent="#accordionSidebar ">
        <div class="bg-white py-2 collapse-inner rounded ">
          <a class="collapse-item " routerLink="/admin/specialization ">All Specialization</a>
          <a class="collapse-item " routerLink="/admin/request-specialization ">Specialization Request</a>
        </div>
      </div>
    </li> -->

    <!-- Nav Item - Charts -->
    <!-- <li class="nav-item ">
      <a class="nav-link " routerLink="/ ">
        <i class="fas fa-fw fa-dollar-sign "></i>
        <span>Payments</span></a>
    </li> -->

    <!-- <li class="nav-item ">
      <a class="nav-link " routerLink="/admin/plan-list ">
        <i class="fas fa-fw fa-key "></i>
        <span>Plan List</span></a>
    </li> -->

    <!-- Nav Item - Tables -->
    <!-- <li class="nav-item ">
      <a class="nav-link " routerLink="/admin/enquiry ">
        <i class="fas fa-fw fa-key "></i>
        <span>Enquiry</span></a>
    </li> -->

    <!-- Nav Item - Tables -->
    <!--   <li class="nav-item ">
      <a class="nav-link " routerLink="/service-category ">
        <i class="fas fa-fw fa-cogs "></i>
        <span>Service Category</span></a>
    </li>
 -->
    <!-- <li class="nav-item ">
      <a class="nav-link " routerLink="/service-category/request ">
        <i class="fas fa-fw fa-cogs "></i>
        <span>New Service Category</span></a>
    </li> -->

    <!--  <li class="nav-item ">
      <a class="nav-link " routerLink="/newsletter-listing ">
        <i class="fas fa-newspaper "></i>
        <span>Newsletters</span></a>
    </li> -->

    <!-- <li class="nav-item ">
        <a class="nav-link " routerLink="/contactus-listing">
            <i class="fas fa-id-card "></i>
            <span>Contact-us Query</span></a>
    </li> -->

    <!-- <li class="nav-item ">
        <a class="nav-link " routerLink="/email-list">
            <i class="fas fa-id-card "></i>
            <span>Email Lists</span></a>
    </li> -->


    <!-- Nav Item - Tables -->
    <!-- <li class="nav-item ">
        <a class="nav-link " routerLink="/change-password">
            <i class="fas fa-fw fa-cogs "></i>
            <span>Settings</span></a>
    </li> -->


    <!-- Sidebar Toggler (Sidebar) -->
    <!-- <div class="text-center d-none d-md-inline ">
      <button class="rounded-circle border-0 " id="sidebarToggle "></button>
    </div> -->

</ul>
<!-- End of Sidebar -->