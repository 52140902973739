import { Injectable } from '@angular/core';
import * as AWS from 'aws-sdk';

import {environment} from '../../environments/environment';


// Initialize the Amazon Cognito credentials provider
AWS.config.update({
  region: environment.REGION,
  credentials: new AWS.CognitoIdentityCredentials({
    IdentityPoolId: environment.IDENTITY_POOL_ID,
  }),
});

@Injectable({
  providedIn: 'root'
})
export class AwsService {

  
  constructor() { }

  //Get single based on image key
  // Source: AWS s3 private bucket
  getImage(image_key:any, bucket_name?: string){
    const s3=new AWS.S3({});

  let params={
    Bucket: bucket_name ?? environment.BUCKET_NAME,      
    Key: image_key
  };

  return s3.getSignedUrlPromise('getObject',params).then(url=>{    
      return url;
    }, err=>{
      console.log("err",err);
      return err;
    });
  }
}
