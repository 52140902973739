import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TOKEN_NAME } from 'src/app/_services/auth.service';

const AUTH_HEADER_KEY = 'Authorization';
const AUTH_PREFIX = 'Bearer';

@Injectable()

export class AuthRequest {

    private headers = new HttpHeaders();
    constructor(private http : HttpClient) {
        const token = localStorage.getItem(TOKEN_NAME);
        if(token) {
          this.headers.append(AUTH_HEADER_KEY, `${AUTH_PREFIX} ${token}`);
        }
    }
}
