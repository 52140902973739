<!-- Begin Page Content -->
<div class="container-fluid">
    <div id="overlay" *ngIf="isLoading">
        <div class="spinner-border align-items-center text-light"></div>
    </div>
    <!-- Page Heading -->
    <div class="d-sm-flex align-items-center justify-content-between mb-4">
        <h1 class="h3 mb-0 text-gray-800">Change Password</h1>
    </div>
    <!-- Content Row -->
    <div class="row">
        <div class="col-lg-8">
            <div class="p-2">

                <form [formGroup]="changePwdForm" (ngSubmit)="onSubmit()">
                    <div class="form-group">
                        <div class="col-sm-6 mb-3 mb-sm-0">
                            <input type="password" formControlName="old_password" class="form-control" placeholder="Old Password" autocomplete="nope">
                            <div *ngIf="f.old_password.invalid && (f.old_password.dirty || f.old_password.touched)" class="invalid-feedback">
                                <div class="error" *ngIf="f.old_password.errors.required">Old password is required</div>
                                <div class="error" *ngIf="f.old_password.errors.old_password">Old password is required</div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="col-sm-6 mb-3 mb-sm-0">
                            <input type="password" formControlName="new_password" class="form-control" placeholder="New Password" autocomplete="nope">
                            <div *ngIf="f.new_password.invalid && (f.new_password.dirty || f.new_password.touched)" class="invalid-feedback">
                                <div class="error" *ngIf="f.new_password.errors.required">New password is required</div>
                                <div class="error" *ngIf="f.new_password.errors.new_password">New password is required</div>
                                <div class="error" *ngIf="f.new_password.errors.minlength">Password must be atleast 8 Char.</div>
                                <div class="error" *ngIf="f.new_password.errors.maxlength">Password must be less then 25 Char.</div>
                            </div>
                        </div>
                    </div>

                    <div class="form-group">
                        <div class="col-sm-6 mb-3 mb-sm-0">
                            <input type="password" formControlName="confirm_password" class="form-control" placeholder="Confirm Password" autocomplete="nope">
                            <div *ngIf="f.confirm_password.invalid && (f.confirm_password.dirty || f.confirm_password.touched)" class="invalid-feedback">
                                <div class="error" *ngIf="f.confirm_password.errors.required"> Confirm password is required</div>
                                <div class="error" *ngIf="f.confirm_password.errors.notEquivalent">Confirm Password must match with password field.</div>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="errorValue" class="form-group col-md-6">
                        <div class="alert-danger">
                            <div *ngIf="errorValue">{{ errorValue }}</div>
                        </div>
                    </div>


                    <div class="form-group col-lg-4">
                        <button type="submit" class="btn btn-primary btn-user btn-block" [disabled]="!changePwdForm.valid">Update Password</button>
                    </div>
                </form>
            </div>
        </div>

    </div>
    <!-- Content Row -->
</div>
<!-- /.container-fluid -->