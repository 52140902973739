import { Injectable } from '@angular/core';
import { map, catchError, retry } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';

import jwt_decode from 'jwt-decode';
export const TOKEN_NAME: string = 'jwt_token';
import { environment } from '../../environments/environment';
import { EmailValidator } from '@angular/forms';
const AUTH_PREFIX = 'Bearer';



@Injectable({
  providedIn: 'root'
})
export class WebService {

  private headers;
  reqHeader: any;
  token: any;

  constructor(private httpClient: HttpClient) { }

  setHeader(){
    this.headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${AUTH_PREFIX} ${localStorage.getItem(TOKEN_NAME)}`
    });
  }

  contactUsListing(data){
    this.setHeader();
    var page = 1;
    if (data.start == 0) {

      var page = 1;
    } else {
      var page = (data.start / 10) + 1;
    }

    if (data.order[0].column == 0) {
      var column = "name";
    } else if (data.order[0].column == 1) {
      var column = "email";
    }
    else if (data.order[0].column == 2) {
      var column = "subject";
    }
    else if (data.order[0].column == 3) {
      var column = "message";
    }
    else if (data.order[0].column == 4) {
      var column = "createdAt";
    } 
    else {
      var column = ""; 
    };

    console.log(data);
    var orderData = {};
    if (column) {
      orderData = { "column": column, "dir": data.order[0].dir };
    } else {
      orderData = "";
    };

    return this.httpClient.post<any>(`${environment.apiUrl}/web/contactus-listing`,
    { "page": page, "limit": data.length, "search_value": new String(data.search.value),"order": orderData },
    { headers: this.headers }
    ).
    pipe(retry(1));
  }

  emailListing(data){
    this.setHeader();
    var page = 1;
    if (data.start == 0) {

      var page = 1;
    } else {
      var page = (data.start / 10) + 1;
    }

    if (data.order[0].column == 0) {
      var column = "name";
    } else if (data.order[0].column == 1) {
      var column = "email";
    }
    else if (data.order[0].column == 2) {
      var column = "phone";
    }    
    else if (data.order[0].column == 5) {
      var column = "createdAt";
    } 
    else {
      var column = ""; 
    };

    console.log(data);
    var orderData = {};
    if (column) {
      orderData = { "column": column, "dir": data.order[0].dir };
    } else {
      orderData = "";
    };

    return this.httpClient.post<any>(`${environment.apiUrl}/web/email-listing`,
    { "page": page, "limit": data.length, "search_value": new String(data.search.value),"order": orderData },
    { headers: this.headers }
    ).
    pipe(retry(1));
  }

  edit_details(name:string,email:string, phone:string, userId:string){
    return this.httpClient.post<any>(`${environment.apiUrl}/web/edit-admin-detail`,{email:email,name:name,phone_number:phone, userId:userId},{ headers: this.headers }).pipe(retry(1));

  }
  report_users(){
    return this.httpClient.get<any>(`${environment.apiUrl}/web/report-users`,{ headers: this.headers }).pipe(retry(1))
  }

  report_list(userId){
    return this.httpClient.post<any>(`${environment.apiUrl}/web/report-user-list`,{userId},{ headers: this.headers }).pipe(retry(1))
  }

  user_images(userId){
    return this.httpClient.post<any>(`${environment.apiUrl}/web/user-images`,{userId},{headers: this.headers}).pipe(retry(1));
  }

  updateStatus(userId:String,status:Boolean){
    return this.httpClient.post<any>(`${environment.apiUrl}/web/update-status`,{user_id:userId,status},{ headers: this.headers }).pipe(retry(1));
  }

  deleteImage(userId:String,images:Array<any>){
    return this.httpClient.post<any>(`${environment.apiUrl}/web/delete-images`,{userId,images},{ headers: this.headers }).pipe(retry(1));
  }

  disableUser(userId:String){
    return this.httpClient.post<any>(`${environment.apiUrl}/web/disable-user`,{userId},{ headers: this.headers }).pipe(retry(1));
  }

  adminDetail(userId:String){
    
    return this.httpClient.post<any>(`${environment.apiUrl}/web/user-detail`,{"userId":userId},
    { headers: this.headers }).
    pipe(retry(1));
  }

  countData(){
    return this.httpClient.get(`${environment.apiUrl}/web/get-count`,{ headers: this.headers }).pipe(retry(1));
  }

  
}
