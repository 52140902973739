<!-- Begin Page Content -->
<div class="container-fluid">
    <div id="overlay" *ngIf="isLoading">
      <div class="spinner-border align-items-center text-light"></div>
    </div>
      <!-- DataTales Example -->
    <div class="card shadow mb-4">
        <div class="card-header d-sm-flex align-items-center justify-content-between py-3">
            <h1 class="h3 mb-0 text-gray-800">Reported Users</h1>
            <button (click)="changeStatus(userId,isActive)" [class]="buttonCss"
            style="float: right;">{{buttonValue}}</button>
        </div>
        <div class="card-body">
            <div class="table-responsive" >
                <table datatable [dtOptions]="dtOptions" class="table table-bordered hover" [dtTrigger]="dtTrigger" >
                    <thead>
                        <tr>
                            <th width="100px">ID</th>
                            <th width="100px">Name</th>
                            <th width="100px">Report Message</th>
                            <th width="100px">Report By</th>
                        </tr>
                    </thead>
                    <tbody *ngIf="users.length != 0">
                        <tr *ngFor="let user of users;let i = index" [ngClass]="{'not-active': !user.is_active}">
                            <td>{{i+1}}</td>
                            <td>{{user.report_to}}</td>
                            <td>{{user.reportMsg}}</td>
                            <td>{{user.report_by}}</td>
                            
                        </tr>
                    </tbody>
                    <tbody *ngIf="users.length == 0">
                    <tr>
                        <td colspan="7" align="center"><b>No Users Found!!</b></td>
                    </tr>
                    </tbody>
                </table>                  
              </div>
            </div>
        </div>
        <div class="card mb-6">
            <div class="card-header">Images</div>
            <div class="form-row p-3">
                <ul class="userImgRemove">
                    <li *ngFor="let image of imageArr;let i=index;" style="display: inline-block!important;padding: 5px;">
                        <img [src]="image" height="100px" width="100px">
                        <img src="/assets/v1/img/cross.png" class="cross_icon" (click)="deleteImage(userId,i)">
                    </li>
                </ul>
            </div>
        </div>
    <div class="card mb-6">
        <div class="card-header"><b>Note:</b></div>
        <div class="form-row p-3">
            <p>Deactivate - The user will not able to use the app anymore. The user will be logged out with message "Your account has been deactivated. Please contact support for details". </p>
        </div>
    </div>
  </div>
  <!-- /.container-fluid -->