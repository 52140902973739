import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { VenueSubscriptionService } from '../_services/venue-subscription.service';
import { VENUE_SIZES, VENUE_INTERVAL } from '../_constants/venue.constants';
import { Interval } from '../_models/venue-subscription.model';
@Component({
  selector: 'app-edit-venue-subscription',
  templateUrl: './edit-venue-subscription.component.html',
  styleUrls: ['./edit-venue-subscription.component.css']
})
export class EditVenueSubscriptionComponent implements OnInit {

  venueSizes: String[] =  VENUE_SIZES;
  venueIntervals: Interval[] = VENUE_INTERVAL;
  subscriptionForm: FormGroup;
  packageDetail: any = {};
  constructor(
    private fb: FormBuilder,
    private venueSubscrService: VenueSubscriptionService,
    private toastr: ToastrService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.subscriptionForm = this.fb.group({
      name: ['', Validators.required],
      description: ['', Validators.required],
      size: [null, Validators.required],
      amount: [null, Validators.required],
      interval: [null, Validators.required]
    });
    this.getSubscriptionPackageDetail();
    this.detactChanges();
  }

  detactChanges() {
    this.subscriptionForm.valueChanges.subscribe(res => console.log(res));
  }

  getSubscriptionPackageDetail() {
    const packageId = this.route.snapshot.params.id;
    this.venueSubscrService.getSubscription(packageId).subscribe({
      next:(res: any) => {
        this.packageDetail = res.data;
        let interval = this.venueIntervals.find(optn => optn.convertedVal == this.packageDetail.interval);
        this.subscriptionForm.patchValue({
          name: this.packageDetail.name,
          description: this.packageDetail.description,
          size: this.packageDetail.size,
          amount: this.packageDetail.amount,
          interval: interval._id
        });
      },
      error: (err) => {
        this.venueSubscrService.handleError(err);
      }
    });
  }

  onUpdateSubscription() {
    let data = this.subscriptionForm.value;
    const packageId = this.route.snapshot.params.id;
    this.venueSubscrService.updateSubscription(packageId, data).subscribe(res => {
      this.toastr.success(res.message);
      this.router.navigate(['/venue/subscription/list']);
    },
    err => {
      const errMsg = (err.name == "HttpErrorResponse") ? err.error.message : err.message
      this.toastr.error(errMsg);
    });
  }

}
