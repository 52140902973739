import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';

import { VenuesService } from '../_services/venues.service';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { environment } from '../../environments/environment';

interface Venue {
  id: number;
  name: string;
  uniquename: string;
}

class DataTablesResponse {
  data: any[]=[];
  draw: number=0;
  recordsFiltered: number=0;
  recordsTotal: number=0;
}

@Component({
  selector: 'app-venues',
  templateUrl: './venues.component.html',
  styleUrls: ['./venues.component.css']
})
export class VenuesComponent implements OnInit {
  dtOptions: DataTables.Settings = {};
  venues: Venue[];

  qrCodeBaseUrl = `${environment.qrCodeBaseUrl}`;

  constructor(
    private venueService: VenuesService,
    private toastr: ToastrService) { }

  search_key: string;
  title = 'Excel';

  dtTrigger: Subject<any> = new Subject();

  ngOnInit(): void {
    this.loadVenues();
  }

  loadVenues() {
    const that = this;
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      serverSide: true,
      processing: true,
      order: [[6,'desc']],
      language: {
        searchPlaceholder: 'Name|Uniquen|Status|Email'
      },
      ajax: (dataTablesParameters: any, callback) => {
        this.search_key = dataTablesParameters.search.value;
        // that.searchFunction(this.search_key);
        this.venueService.venuesListing(dataTablesParameters).
          subscribe(resp => {
            that.venues = resp.data.venues;
            callback({
              recordsTotal: resp.data.total_count,
              recordsFiltered: resp.data.total_count,
              data: []
            });

            // Calling the DT trigger to manually render the table
            // this.dtTrigger.next();
          }, err => {
            callback({
              processing: false,
              recordsTotal: 0,
              recordsFiltered: 0,
              data: []
            });
          });
      },
      columns: [{ data: 'name' }, { data: 'uniquename' }, { data: 'description' }, { data: 'type' }, { data: 'contactInfo' }, { data: 'user_id' }, { data: 'createdAt' }, {data: 'statusname'}]
      // columnDefs: [{ "orderable": false, "targets": 5 }]

    };
  }

  searchFunction(search_key = null) {

    let data_filter = { search: { value: search_key } };
  }



}
