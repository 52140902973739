import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { map, catchError, retry } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import jwt_decode from 'jwt-decode';
export const TOKEN_NAME: string = 'jwt_token';
import { environment } from '../../environments/environment';
import { EmailValidator } from '@angular/forms';
const AUTH_PREFIX = 'Bearer';

@Injectable({
  providedIn: 'root'
})
export class AuthService {  

  private headers;
  reqHeader: any;
  token: any;

  constructor(private httpClient: HttpClient) { }

  setHeader(){
    this.headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${AUTH_PREFIX} ${localStorage.getItem(TOKEN_NAME)}`
    });
  }

  login(email: string, password: string) {
    return this.httpClient.post<any>(`${environment.apiUrl}/admin/login`, { email: email, password: password })
      .pipe(retry(0)
      );
  }

  logout() {
    this.setHeader();

    return this.httpClient.get<any>(`${environment.apiUrl}/admin/logout`, { headers: this.headers })
      .pipe(retry(0));
  }

  forgot_password(phone_number:string){
    return this.httpClient.post<any>(`${environment.apiUrl}/admin/forgot-password`,{phone_number:phone_number})
    .pipe(retry(0));
  }

  verify_otp(phone_number:String,otp:Number){
    return this.httpClient.post<any>(`${environment.apiUrl}/admin/verify-otp`,{phone_number:phone_number,otp:otp})
    .pipe(retry(0));
  }

  reset_password(phone_number:string,otp:number, password:string, c_password:string){
    return this.httpClient.post<any>(`${environment.apiUrl}/admin/reset-password`,{phone_number:phone_number, otp:otp, password:password, confirm_password:c_password}).pipe(retry(0));
  }

  webForm(name:String, email: String, phone_number: String, location: String,userId:String){
    return this.httpClient.post<any>(`${environment.apiUrl}/web/sender-detail`,{name:name,email:email, phone_number:phone_number, location:location, userId:userId})
    .pipe(retry(0));
  }

  getToken(): string {
    return localStorage.getItem(TOKEN_NAME);
  }

  setToken(token: string): void {
    localStorage.setItem(TOKEN_NAME, token);
  }

  getTokenExpirationDate(token: string): Date {
    let decoded:any = jwt_decode(token);
    
    if (decoded.exp === undefined) return null;

    const date = new Date(0);
    date.setUTCSeconds(decoded.exp);    
    return date;
  }

  clearLocalStorage(): void {
    localStorage.clear();
  }

  isTokenExpired(token?: string): boolean {
    if(!token) token = this.getToken();    
    if(!token) return false;

    const date = this.getTokenExpirationDate(token);    
    
    if(date === undefined) return false;
    return (date.valueOf() > new Date().valueOf());
  }
}
