import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-account-details',
  templateUrl: './account-details.component.html',
  styleUrls: ['./account-details.component.css']
})
export class AccountDetailsComponent implements OnInit {
  currentUser:any;
  constructor() { }

  ngOnInit(): void {
    this.currentUser=JSON.parse(localStorage.getItem('currentUser'));    
  }

}
