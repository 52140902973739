import { Component, OnInit } from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {Mailto,NgxMailtoService} from 'ngx-mailto';
import {WebService} from '../_services/web.service';


class ConatctUs {  
  name: string;
  subject: string;
  message: string;
  email: string;  
}


@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit {

  dtOptions: DataTables.Settings = {};
  contacts: ConatctUs[];
  mailTo:object={}; 
  start:number=0;
  

  constructor(private toastr:ToastrService,
    private webService: WebService,
    private mailToService:NgxMailtoService) { }

  ngOnInit(): void {

    $("#DataTables_Table_1_filter lable.input").attr("(keyp)","searchFunc()");
    const that = this;
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      serverSide: true,
      processing: true,
      order:[[4, 'desc']],
      ajax: (dataTablesParameters: any, callback) => {
        this.webService.contactUsListing(dataTablesParameters).subscribe(resp=>{
          console.log(resp);
          that.contacts = resp.data.contact_listing;
          
          callback({
            recordsTotal: resp.data.total_count,
            recordsFiltered: resp.data.filteredData,
            data: []
          });

          this.start=0;
          if(resp.data.contact_listing.length>0){
            this.start=1;
          }
          // $("#DataTables_Table_0_info").html(`Showing ${this.start} to ${resp.data.contact_listing.length} of ${resp.data.total_count} entries`);
           // Calling the DT trigger to manually render the table
          // this.dtTrigger.next();
        },err=>{
          console.log(err);
          this.toastr.error(err.error);
                    callback({
                      processing: false,
                      recordsTotal: 0,
                      recordsFiltered: 0,
                      data: []
                    });
        });
      },
      columns: [{ data: 'name' }, {data: 'email' }, { data: 'subject' }, {data:'message'},{data:'createdAt'}],
      "columnDefs": [{ "orderable": false, "targets": 5 }]
    };
  }

  
  mailMe(formElement){
    
    this.mailTo ={
      receiver:formElement.email,
      subject:formElement.subject,
      body:formElement.message
    }
    this.mailToService.open(this.mailTo)
  }

}
