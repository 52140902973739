<div class="container-fluid">
    <div id="overlay" *ngIf="isLoading">
        <div class="spinner-border align-items-center text-light"></div>
    </div>
    <!-- DataTales Example -->
    <div class="card shadow mb-4">
        <div class="card-header d-sm-flex align-items-center justify-content-between py-3">
            <h1 class="h3 mb-0 text-gray-800">Edit Detail</h1>
        </div>
        <div class="card-body">
            <form [formGroup]="editForm" (ngSubmit)="onSubmit()">
                <div class="form-group row">
                    <label for="name" class="col-sm-2 col-form-label">Name</label>
                    <div class="col-sm-3">
                        <input type="text" class="form-control" formControlName="name">
                    </div>
                </div>
                <div class="form-group row">
                    <label for="email" class="col-sm-2 col-form-label">Email</label>
                    <div class="col-sm-3">
                        <input type="text" class="form-control" formControlName="email">
                    </div>
                </div>
                <div class="form-group row">
                    <label for="phone" class="col-sm-2 col-form-label">Phone</label>
                    <div class="col-sm-3">
                        <input type="text" class="form-control" formControlName="phone">
                    </div>
                </div>
                <div class="form-group text-center row">
                    <div class="col-md-6 text-center">
                        <button type="submit" class="btn btn-primary">Submit</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>