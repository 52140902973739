import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { VenuesService } from '../_services/venues.service';
import { Venue } from '../_models/venue.model';
import { AwsService } from '../_services/aws.service';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-venue-detail',
  templateUrl: './venue-detail.component.html',
  styleUrls: ['./venue-detail.component.css']
})
export class VenueDetailComponent implements OnInit {
  venueDetail:Venue; 
  imageArr: Array<string> = [];
  selectedVenueId: string;
  comment: string;
  userEmail: string;
  modalTitle: string;
  venueStatus: number;
  constructor(
    private venueService: VenuesService,
    private route: ActivatedRoute,
    private awsService: AwsService,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.getVenueDetail();
  }

  getVenueDetail() {
    const venueId = this.route.snapshot.params.id;
    this.venueService.venueDetail(venueId).subscribe({
      next:(res: any) => {
        this.venueDetail = res.data;
        this.getVenueImages();
      },
      error: (err) => {
        this.venueService.handleError(err);
      }
    });
  }

  getVenueImages() {
    if(this.venueDetail.images && this.venueDetail.images.length){
      let images = this.venueDetail.images;
      images.forEach(img => {
        if(img && img['image']) {
          this.awsService.getImage(img['image'], environment.VENUE_IMAGE_BUCKET_ORIGINAL).then(url=>{
            this.imageArr.push(url);
          })
        }
      });    
    }
  }

  changeStatus(id: string, status: number, email: string) {
    let vStatus = status == 6 ? 1 : status; 
    const commentText = vStatus == 2 || vStatus == 5 ? this.comment : '';
    this.venueService.updateStatus(id, vStatus, commentText, email).subscribe(res=>{
      // update venue status and hide modal
      this.venueDetail.status = vStatus;
      this.toggleModal('openVenueModal');
      this.toastr.success(res.message);
      // reload on reject or pause venue
      if(vStatus == 2 || vStatus == 5) {
        this.getVenueDetail();
      }
    },err =>{
      const errMsg = (err.name == "HttpErrorResponse") ? err.error.message : err.message
      this.toastr.error(errMsg);
    })
  }

  onReasonTextChange(commentText: any) {
    // trim text
    this.comment = commentText.trimStart();
  }

  toggleModal(id:string) {
    (<any>$(`#${id}`)).modal('toggle');
  }

  updateVenueStatusModal(status: number, venueId: string, email:string = '') {
    this.selectedVenueId = venueId;
    this.userEmail = email;
    this.venueStatus = status;
    this.modalTitle = status == 1 ? 'Approve' : status == 2 ? 'Reject' : status == 5 ? 'Pause' : status == 6 ? 'Unpause' : '';
    (<any>$('#openVenueModal')).modal('show');
  }

  getClassName(val: number) {
    return (val == 2 || val == 5) ? 'btn btn-danger' : 'btn btn-success';
  }
}
