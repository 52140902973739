<!-- Begin Page Content -->
<div class="container-fluid">

    <!-- DataTales Example -->
    <div class="card shadow mb-4">
        <div class="card-header d-sm-flex align-items-center justify-content-between py-3">
            <h1 class="h3 mb-0 text-gray-800">Contact Us</h1>
        </div>
        <div class="card-body">
            <div class="table-responsive">
                <table datatable [dtOptions]="dtOptions" class="table table-bordered hover" width="100%" cellspacing="0">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Subject</th>
                            <th>Message</th>
                            <th>Date</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody *ngIf="contacts?.length != 0">

                        <tr *ngFor="let contact of contacts">
                            <td class="name">{{ (contact.name !== '') ? contact.name : 'N/A' }}</td>
                            <td class="email">{{ (contact.email !== '') ? contact.email : 'N/A' }}</td>
                            <td class="subject">{{ (contact.subject !== '') ? contact.subject : 'N/A' }} </td>
                            <td class="message">{{ (contact.message !== '') ? contact.message : 'N/A' }} </td>
                            <td class="Datatime">{{ (contact.createdAt !== '') ? (contact.createdAt | date:'short') : 'N/A' }}</td>
                            <td>
                                <button class="btn btn-primary" (click)="mailMe(contact)">Reply</button>
                            </td>


                        </tr>
                    </tbody>
                    <tbody *ngIf="contacts?.length == 0">
                        <tr>
                            <td colspan="3" class="no-data-available">No records found!</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>

</div>
<!-- /.container-fluid -->