<!-- Page Wrapper -->
<div id="wrapper">

    <!-- Add Sidebar -->
    <app-sidebar></app-sidebar>

    <!-- Content Wrapper -->
    <div id="content-wrapper" class="d-flex flex-column">

        <!-- Main Content -->
        <div id="content">

            <!-- Topbar -->
            <app-header></app-header>

            <router-outlet></router-outlet>

        </div>
        <!-- End of Main Content -->

        <!-- Add footer -->
        <app-footer></app-footer>

    </div>
    <!-- End of Content Wrapper -->

</div>
<!-- End of Page Wrapper -->