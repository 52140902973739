import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
export const TOKEN_NAME: string = 'jwt_token';
import { environment } from '../../environments/environment';
const AUTH_PREFIX = 'Bearer';
import { retry, catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { Venue } from '../_models/venue.model';
@Injectable({
  providedIn: 'root'
})
export class VenueSubscriptionService {

  private headers;
  reqHeader: any;
  token: any;

  constructor(private httpClient: HttpClient) { 
    this.setHeader();
  }
  
  setHeader() {
    this.headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${AUTH_PREFIX} ${localStorage.getItem(TOKEN_NAME)}`
    });
  }
  handleError(error: HttpErrorResponse) {
    console.log(`Status:${error.status}`);
    return throwError(error);
  }

  subscriptionsListing(data: any) {
    var page = 1;
    if (data.start == 0) {
      var page = 1;
    } else {
      var page = (data.start / 10) + 1;
    }

    if (data.order[0].column == 0) {
      var column = "name";
    } else if (data.order[0].column == 1) {
      var column = "description";
    }
    else if (data.order[0].column == 2) {
      var column = "size";
    }
    else if (data.order[0].column == 3) {
      var column = "amount";
    }
    else if (data.order[0].column == 4) {
      var column = "interval";
    }
    else if (data.order[0].column == 5 ) {
      var column = "status";
    }
    else if (data.order[0].column == 6 ) {
      var column = "noofusers";
    }
    else {
      var column = "";
    };

    // console.log(data);
    var orderData = {};
    if (column) {
      orderData = { "column": column, "dir": data.order[0].dir };
    } else {
      orderData = "";
    };

    return this.httpClient.post<any>(`${environment.apiUrl}/admin/venue/subscription/list`,
      { "page": page, "limit": data.length, "search_value": new String(data.search.value), "order": orderData },
      { headers: this.headers }
    ).pipe(catchError(this.handleError));
  }

  createSubscription(data: any): Observable<any> {
    return this.httpClient.post<any>(`${environment.apiUrl}/admin/venue/subscription/add`, data, 
      { headers: this.headers }
    ).pipe(catchError(this.handleError));
  }

  getSubscription(id: any): Observable<any> {
    return this.httpClient.get<any>(`${environment.apiUrl}/admin/venue/subscription/view/${id}`,
      { headers: this.headers }
    ).pipe(catchError(this.handleError));
  }

  updateSubscription(id: string, data: any): Observable<any> {
    return this.httpClient.put<any>(`${environment.apiUrl}/admin/venue/subscription/edit/${id}`, data, 
      { headers: this.headers }
    ).pipe(catchError(this.handleError));
  }

  updateUserSubscription(data: any): Observable<any> {
    return this.httpClient.patch<any>(`${environment.apiUrl}/admin/venue/update-subscription`, data, 
      { headers: this.headers }
    ).pipe(catchError(this.handleError));
  }

  updateVenueSubscrStatus(data: any) {
    return this.httpClient.patch<any>(`${environment.apiUrl}/admin/update-venue-subscription-status`,data,{ headers: this.headers }).pipe(catchError(this.handleError));
  }

  getPackagesList(): Observable<any> {
    return this.httpClient.get<any>(`${environment.apiUrl}/admin/venue/packages/list`,
      { headers: this.headers }
    ).pipe(catchError(this.handleError));
  }
}
