import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
export const TOKEN_NAME: string = 'jwt_token';
import { environment } from '../../environments/environment';
const AUTH_PREFIX = 'Bearer';
import { retry, catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { Venue } from '../_models/venue.model';
@Injectable({
  providedIn: 'root'
})
export class VenuesService {

  private headers;
  reqHeader: any;
  token: any;

  constructor(private httpClient: HttpClient) { 
    this.setHeader();
  }
  
  setHeader() {
    this.headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${AUTH_PREFIX} ${localStorage.getItem(TOKEN_NAME)}`
    });
  }
  venuesListing(data: any) {
    var page = 1;
    if (data.start == 0) {
      var page = 1;
    } else {
      var page = Math.ceil(data.start / data.length) + 1;
    }

    if (data.order[0].column == 0) {
      var column = "name";
    } else if (data.order[0].column == 1) {
      var column = "uniquename";
    }
    else if (data.order[0].column == 2) {
      var column = "description";
    }
    else if (data.order[0].column == 3) {
      var column = "type";
    }
    else if (data.order[0].column == 4) {
      var column = "contactinfo.name";
    }
    else if (data.order[0].column == 5 ) {
      var column = "user_info.name";
    }
    else if (data.order[0].column == 6 ) {
      var column = "createdAt";
    }
    else if (data.order[0].column == 7 ) {
      var column = "statusname";
    }
    else {
      var column = "";
    };

    // console.log(data);
    var orderData = {};
    if (column) {
      orderData = { "column": column, "dir": data.order[0].dir };
    } else {
      orderData = "";
    };

    return this.httpClient.post<any>(`${environment.apiUrl}/admin/list-venues`,
      { "page": page, "limit": data.length, "search_value": new String(data.search.value), "order": orderData },
      { headers: this.headers }
    ).pipe(catchError(this.handleError));
  }

  venueDetail(id:string):Observable<Venue> {
    return this.httpClient.get<Venue>(`${environment.apiUrl}/admin/venue/${id}`, { headers: this.headers }).pipe(retry(1),catchError(this.handleError));
  }

  updateStatus(id:string, status:number, comment: string, email: string) {
    let data:any = { "status": status };
    if(status == 2) { // reject venue
      data = {...data, "comment": comment};
    } else if(status == 5) { // pause venue
      data = {...data, "pause_comment": comment};
    }
    if(email) {
      data = {...data, "email": email};
    }
    return this.httpClient.patch<any>(`${environment.apiUrl}/admin/venue/update-status/${id}`, 
      data,
      { headers: this.headers }
    ).pipe(catchError(this.handleError));
  }

  handleError(error: HttpErrorResponse) {
    console.log(`Status:${error.status}`);
    return throwError(error);
  }
}
