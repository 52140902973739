<!-- Begin Page Content -->
<div class="container-fluid">
  <div id="overlay" *ngIf="isLoading">
    <div class="spinner-border align-items-center text-light"></div>
</div>
    <!-- DataTales Example -->
    <div class="card shadow mb-4">
        <div class="card-header d-sm-flex align-items-center justify-content-between py-3">
            <h1 class="h3 mb-0 text-gray-800">Reported Users</h1>
            
        </div>
        <div class="card-body">
            <div class="table-responsive" >
                <table datatable [dtOptions]="dtOptions" class="row-border hover" [dtTrigger]="dtTrigger" >
                    <thead>
                      <tr>
                        <th width="100px">ID</th>
                        <th width="100px">Name</th>
                        <th width="100px">Report Message</th>
                        <th width="100px">Report By</th>
                        <th width="100px">Status</th>
                        <th width="100px">Action</th>
                      </tr>
                    </thead>
                    <tbody *ngIf="users.length != 0">
                      <tr *ngFor="let user of users;let i = index" [ngClass]="{'not-active': !user.is_active}">
                        <td>{{i+1}}</td>
                        <td>{{user.report_to}}</td>
                        <td>{{user.reportMsg}}</td>
                        <td>{{user.report_by}}</td>
                        <td *ngIf="user.is_active==true">Active</td>
                        <td *ngIf="user.is_active==false">In-active</td>
                        <!--td><a href="javascript:void(0)" (click)="changeStatus(user.user_id,user.is_active)">Active/Deactive</a></td-->
                        <td><a routerLink="/report-list/{{ user.user_id }}" class="btn btn-primary btn-sm">View</a></td>
                      </tr>
                    </tbody>
                    <tbody *ngIf="users.length == 0">
                      <tr>
                        <td colspan="7" align="center"><b>No Users Found!!</b></td>
                      </tr>
                    </tbody>
                  </table>                  
            </div>
        </div>
    </div>
</div>
<!-- /.container-fluid -->