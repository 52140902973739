<!-- Begin Page Content -->
<div class="container-fluid">
    <div class="row">
        <div class="col-lg-12">
            <!-- Default Card Example -->
            <div class="card mb-6">
                <div class="card-header">
                    Update Subscription
                </div>

                <!-- add subscription package -->
                <form [formGroup]="subscriptionForm" (ngSubmit)="onUpdateSubscription()">
                    <div class="form-row p-3">
                        <div class="form-group col-lg-6">
                            <label>Name</label>
                            <input type="text" formControlName="name" class="form-control" [value]="packageDetail.name" placeholder="Name">
                        </div>
                        <div class="form-group col-lg-6">
                            <label>Description</label>
                            <input type="text" formControlName="description" class="form-control" [value]="packageDetail.description" placeholder="Description Text">
                        </div>
                        <div class="form-group col-lg-6">
                            <label>Venues Count</label>
                            <select id="size" formControlName="size" class="form-select">
                                <option value="null" disabled>Venue Size</option>
                                <option *ngFor="let option of venueSizes" [value]="option">{{option}}</option>
                            </select>
                        </div>
    
                        <div class="form-group col-lg-6">
                            <label>Amount (In Dollars)</label>
                            <input type="number" formControlName="amount" class="form-control" [value]="packageDetail.amount" placeholder="Amount">
                        </div>
    
                        <div class="form-group col-lg-6">
                            <label>Interval</label>
                            <select id="interval" formControlName="interval"  class="form-select">
                                <option value="null" disabled>Venue Interval</option>
                                <option *ngFor="let option of venueIntervals; let key = index;" [value]="option._id">{{option.value}}</option>
                            </select>
                        </div>

                        <div class="form-group col-lg-6"></div>

                        <div class="form-group col-lg-6">
                            <button type="submit" class="btn btn-primary" [disabled]="!subscriptionForm.valid">Save</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>