import { Component, OnInit } from '@angular/core';
import {DataTableDirective} from 'angular-datatables';
import {Subject} from 'rxjs';
import {WebService} from '../_services/web.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';
import {AwsService} from '../_services/aws.service';

@Component({
  selector: 'app-report-list',
  templateUrl: './report-list.component.html',
  styleUrls: ['./report-list.component.css']
})
export class ReportListComponent implements OnInit {
  dtOptions: DataTables.Settings = {};
  dtElement:DataTableDirective;
  dtTrigger: Subject<any> = new Subject<any>();
  users:Array<any>=[];
  isLoading:boolean=true;
  showTable:boolean=false;
  isActive:boolean=true;
  buttonValue:string="";
  buttonCss:string="";
  userId:string="";
  userDetails:any={};
  imageArr:Array<any>=[];
  images:Array<any>=[];


  constructor(private webService: WebService,private toastr: ToastrService,private route:ActivatedRoute,private awsService:AwsService) { }

  ngOnInit(): void {

    let id= this.route.snapshot.params.userId
    this.userId=id;
    this.dtOptions = {
      pagingType: 'full_numbers',            
      processing: true,
      // destroy: true,
      ordering: true,
      columnDefs: [{ 
        targets: 0          
      }]
    };

    this.webService.report_list(id).subscribe(res=>{
      
      if(res.data && res.data.length){
        this.isActive=res.data[0].is_active?true:false;
        this.buttonValue=res.data[0].is_active?"Deactivate":"Activate";
        this.buttonCss=res.data[0].is_active?"btn-danger":"btn-success"
      }
      this.users=res.data;
      this.dtTrigger.next();
      this.showTable=true;    
      this.isLoading=false;   
      
    },err=>{
      this.isLoading=false;
      this.toastr.error(err.message);      
    })

    this.webService.user_images(id).subscribe(res=>{
      
      this.userDetails=res.data;
      console.log(res.data);    
      if(this.userDetails && this.userDetails.length){
        this.images=this.userDetails;
        console.log(this.images);
        this.images.forEach(image =>{
          if(image){
            this.awsService.getImage(image).then(url=>{              
              this.imageArr.push(url);
            })
          }
        });
        
      }

    },err=>{
            
    })
  }

  changeStatus(user_id,status){
    let updatedStatus=status?false:true;    
    this.webService.updateStatus(user_id,updatedStatus).subscribe(res=>{
      setTimeout(()=>{        
        location.reload();        
      },1000);      
      this.toastr.success(res.message);
    },err =>{
      this.toastr.error(err.message);
    })
    
  }

  deleteImage(userId,imgIndex){
    let imagesArr=[];
    this.isLoading=true;
    imagesArr.push(this.images[imgIndex]);
    this.webService.deleteImage(userId,imagesArr).subscribe(res=>{ 
      setTimeout(()=>{        
        location.reload();        
      },1000);
      this.isLoading=false;
      this.toastr.success(res.message);
    },err =>{
      this.isLoading=false;
      this.toastr.error(err.message);
    })
  }

}
