import { Injectable } from '@angular/core';
import {Subject, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PassValueService {

  private subject= new Subject<string>();

  constructor() { }

  sendMessage(message){
    this.subject.next(message);
  }

  receiveMessage():Observable<string>{
    return this.subject.asObservable();
  }
}
