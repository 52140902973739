import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { WebService } from '../_services/web.service';

@Component({
  selector: 'app-edit-account-details',
  templateUrl: './edit-account-details.component.html',
  styleUrls: ['./edit-account-details.component.css']
})
export class EditAccountDetailsComponent implements OnInit {

  editForm:FormGroup
  currentUser:any;
  adminDetail:any;
  name:string;
  isLoading:boolean=false;

  constructor(
    private fb:FormBuilder,
    private toastr: ToastrService,
    private webService:WebService
  ) { }

  ngOnInit(): void {
    this.currentUser=JSON.parse(localStorage.getItem("currentUser"));
    
    this.editForm=this.fb.group({
      name:[this.currentUser.name],
      email:[this.currentUser.email,[Validators.required,Validators.email]],
      phone:[this.currentUser.phone,[Validators.required]]
    })
    
  }

  onSubmit(){
    this.isLoading=true;

    if(this.editForm.invalid){
      this.toastr.error("Email and Phone is required");
      return;
    }
    this.webService.edit_details(this.f.name.value, this.f.email.value,this.f.phone.value,this.currentUser.userId).subscribe(res=>{
      
      localStorage.setItem('currentUser', JSON.stringify({ email:res.data.user_detail.email ,  name:res.data.user_detail.name,
        phone:res.data.user_detail.phone,
      userId:this.currentUser.userId}));

      this.toastr.success(res.message);
      this.isLoading=false;
    }, err=>{      
      this.toastr.error(err.error.message);
      this.isLoading=false;
    })

  }

  get f(){
    return this.editForm.controls;
  }

}
