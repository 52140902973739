import { NgModule, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {HashLocationStrategy,LocationStrategy} from '@angular/common';
import {DataTablesModule} from 'angular-datatables';

import {HttpRequest, HTTP_INTERCEPTORS,HttpClientModule} from '@angular/common/http';
import {FormsModule,ReactiveFormsModule} from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import {NgxUiLoaderModule, NgxUiLoaderConfig,SPINNER, POSITION, PB_DIRECTION,NgxUiLoaderRouterModule } from 'ngx-ui-loader';

import {NgxMailtoModule} from 'ngx-mailto';


import { AuthRequest } from 'src/app/_guard/auth-request';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { FooterComponent } from './_layout/footer/footer.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { SidebarComponent } from './_layout/sidebar/sidebar.component';
import { HeaderComponent } from './_layout/header/header.component';
import {ChangePasswordComponent  } from './change-password/change-password.component';
import { HomeComponent } from './home/home.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { VerifyOtpComponent } from './verify-otp/verify-otp.component';
import { WebFormComponent } from './web-form/web-form.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { EmailsListComponent } from './emails-list/emails-list.component';
import { AccountDetailsComponent } from './account-details/account-details.component';
import { EditAccountDetailsComponent } from './edit-account-details/edit-account-details.component';
import { UsersComponent } from './users/users.component';
import {UserDetailComponent} from  './user-detail/user-detail.component';
import { QRCodeModule } from 'angularx-qrcode';
import { ReportUserComponent } from './report-user/report-user.component';
import { ReportListComponent } from './report-list/report-list.component';
import { VenuesComponent } from './venues/venues.component';
import { VenueDetailComponent } from './venue-detail/venue-detail.component';
import { VenuesSubscriptionComponent } from './venues-subscription/venues-subscription.component';
import { AddVenueSubscriptionComponent } from './venues-subscription/add-venue-subscription.component';
import { EditVenueSubscriptionComponent } from './venues-subscription/edit-venue-subscription.component';




const ngxUiLoaderConfig: NgxUiLoaderConfig = {  
  bgsColor: 'red',
  bgsPosition: POSITION.bottomCenter,
  bgsSize: 40,
  bgsType: SPINNER.rectangleBounce, // background spinner type
  fgsType: SPINNER.chasingDots, // foreground spinner type
  pbDirection: PB_DIRECTION.leftToRight, // progress bar direction
  pbThickness: 5, // progress bar thickness,
  hasProgressBar: true
}


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    FooterComponent,
    DashboardComponent,
    SidebarComponent,
    HeaderComponent,
    ChangePasswordComponent,
    HomeComponent,
    ForgotPasswordComponent,
    VerifyOtpComponent,
    WebFormComponent,
    ResetPasswordComponent,
    ContactUsComponent,
    EmailsListComponent,
    AccountDetailsComponent,
    EditAccountDetailsComponent,
    UsersComponent,
    UserDetailComponent,
    ReportUserComponent,
    ReportListComponent,
    VenuesComponent,
    VenueDetailComponent,
    VenuesSubscriptionComponent,
    AddVenueSubscriptionComponent,
    EditVenueSubscriptionComponent
  ],
  imports: [
    FormsModule,
    QRCodeModule,
    DataTablesModule,
    ReactiveFormsModule,
    BrowserModule,
    HttpClientModule,
    NoopAnimationsModule,
    AppRoutingModule,
    NgxMailtoModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    NgxUiLoaderRouterModule.forRoot({ showForeground: true }),
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
    }),
  ],
  providers: [
    {
      provide: HttpRequest,
      useClass: AuthRequest
    },
    {
      provide:LocationStrategy,
      useClass:HashLocationStrategy
    }
    // ,
    // {
    //   provide: ErrorHandler,
    //   useClass: AuthErrorHandler
    // },
    // { provide: HTTP_INTERCEPTORS, 
    //   useClass: HttpInterceptorService, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
