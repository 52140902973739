<!-- Begin Page Content -->
<div class="container-fluid">

    <!-- DataTales Example -->
    <div class="card shadow mb-4">
        <div class="card-header d-sm-flex align-items-center justify-content-between py-3">
            <h1 class="h3 mb-0 text-gray-800">Emails</h1>
        </div>
        <div class="card-body">
            <div class="table-responsive">
                <table datatable [dtOptions]="dtOptions" class="table table-bordered hover" width="100%" cellspacing="0">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Phone</th>
                            <th>Owner Details</th>
                            <th>Location</th>
                            <th>Date</th>
                        </tr>
                    </thead>
                    <tbody *ngIf="records?.length != 0">

                        <tr *ngFor="let rec of records">
                            <td class="name">{{ (rec.userinfo.name !== '') ? rec.userinfo.name : '-' }}</td>
                            <td class="email">{{ (rec.userinfo.email !== '') ? rec.userinfo.email : '-' }}</td>
                            <td class="phone">{{ (rec.userinfo.phone_number !== '') ? rec.userinfo.phone_number : '-' }} </td>

                            <td class="phone_owner">Name:{{rec.user.length > 0?rec.user[0].name:"-"}}
                                <br> Phone: {{rec.user.length >0?rec.user[0].phone_number:"-"}}
                            </td>
                            <td class="Location">Lat: {{ (rec.location.lat !== '') ? rec.location.lat : '-' }}
                                <br>Long: {{ (rec.location.long !=='') ? rec.location.long : '-' }}
                                <a target="_blank" href="{{ (rec.location.google_map !== '') ? rec.location.google_map : '#' }}" *ngIf="(rec.location.google_map !== '')"><br>Map</a>
                                <br> Address: {{ (rec.location.address !== '') ? rec.location.address : '-' }}
                            </td>
                            <td class="Datatime">{{ (rec.createdAt !== '') ? (rec.createdAt | date:'short') : 'N/A' }}</td>
                        </tr>
                    </tbody>
                    <tbody *ngIf="records?.length == 0">
                        <tr>
                            <td colspan="3" class="no-data-available">No records found!</td>
                        </tr>
                        <tbody>
                </table>
            </div>
        </div>
    </div>

</div>
<!-- /.container-fluid -->