<!-- Begin Page Content -->
<div class="container-fluid">
    <div class="row">
        <div class="col-lg-12">
            <!-- Default Card Example -->
            <div class="card mb-6">
                <div class="card-header">
                    Venue Detail
                    <div style="text-align:right;">
                        <ng-container *ngIf="venueDetail && venueDetail.status && venueDetail.status == 3">
                            <button (click)="updateVenueStatusModal(1, venueDetail._id, venueDetail?.user?.email)"
                                class="btn btn-success ml-2">Approve</button>
                            <button class="btn btn-danger ml-2"
                                (click)="updateVenueStatusModal(2, venueDetail._id, venueDetail?.user?.email)">Reject</button>
                        </ng-container>
                        <ng-container *ngIf="venueDetail && venueDetail.status && venueDetail.status == 1">
                            <button class="btn btn-secondary" [disabled]="true">Approved</button>
                        </ng-container>
                        <ng-container *ngIf="venueDetail && venueDetail.status && venueDetail.status == 2">
                            <button class="btn btn-danger" [disabled]="true">Rejected</button>
                        </ng-container>
                        <!-- pause venue -->
                        <ng-container *ngIf="venueDetail && venueDetail.status && venueDetail.status == 1">
                            <button (click)="updateVenueStatusModal(5, venueDetail._id, venueDetail?.user?.email)" class="ml-2 btn btn-danger">Pause</button>
                        </ng-container>
                        <!-- Unpause venue -->
                        <ng-container *ngIf="venueDetail && venueDetail.status && venueDetail.status == 5">
                            <button (click)="updateVenueStatusModal(6, venueDetail._id, venueDetail?.user?.email)" class="ml-2 btn btn-success">Unpause</button>
                        </ng-container>
                    </div>
                </div>

                <div class="form-row p-3">
                    <div class="form-group col-lg-6">
                        <label>Name</label>
                        <input type="text" class="form-control capitalize" placeholder="Name"
                            value="{{(venueDetail && venueDetail.name) ? venueDetail.name : 'N/A' }}" [readonly]="true">
                    </div>
                    <div class="form-group col-lg-6">
                        <label>Uniquename</label>
                        <input type="text" class="form-control" placeholder="Uniquename"
                            value="{{(venueDetail && venueDetail.uniquename) ? venueDetail.uniquename : 'N/A' }}"  [readonly]="true">
                    </div>
                    <div class="form-group col-lg-6">
                        <label>Description</label>
                        <input type="text" class="form-control" placeholder="Description Text"
                            value="{{ (venueDetail && venueDetail.description) ? venueDetail?.description : 'N/A' }}"
                            [readonly]="true">
                    </div>

                    <div class="form-group col-lg-6">
                        <label>Type</label>
                        <input type="text" class="form-control" placeholder="Type"
                            value="{{ (venueDetail && venueDetail.type) ? venueDetail.type.name : 'N/A' }}"
                            [readonly]="true">
                    </div>

                    <div class="form-group col-lg-6">
                        <label>Ambiance</label>
                        <input type="text" class="form-control" placeholder="Ambiance"
                            value="{{ (venueDetail && venueDetail.ambiance && venueDetail.ambiance.name) ? venueDetail.ambiance.name : 'N/A' }}"
                            [readonly]="true">
                    </div>

                    <div class="form-group col-lg-6">
                        <label>Special Offer</label>
                        <input type="text" class="form-control" placeholder="Special Offer"
                            value="{{ (venueDetail && venueDetail.specialoffer) ? venueDetail.specialoffer : 'N/A' }}"
                            [readonly]="true">
                    </div>

                    <div class="form-group col-lg-6">
                        <label>Extra Info</label>
                        <input type="text" class="form-control" placeholder="Extra Info"
                            value="{{ (venueDetail && venueDetail.extrainfo) ? venueDetail.extrainfo : 'N/A' }}"
                            [readonly]="true">
                    </div>

                    <div class="form-group col-lg-6">
                        <label>Status</label>
                        <input type="text" class="form-control" placeholder="Status"
                            value="{{ venueDetail && venueDetail.statusname }}" [readonly]="true">
                    </div>

                    <div class="form-group col-lg-6">
                        <label>Creation Date</label>
                        <input type="text" class="form-control" placeholder="Created At"
                            value="{{ (venueDetail && venueDetail.createdAt) ? venueDetail.createdAt : 'N/A' }}"
                            [readonly]="true">
                    </div>

                    <div class="form-group col-lg-6">
                        <label [ngClass]="{'text-danger': venueDetail?.comment !== ''}">Last Rejection Reason</label>
                        <textarea class="form-control" placeholder="Comment"
                            value="{{ (venueDetail && venueDetail.comment) ? venueDetail.comment : 'N/A' }}"
                            [readonly]="true"></textarea>
                    </div>

                    <div class="form-group col-lg-6">
                        <div class="form-row">
                            <div class="form-group col-11"><label>Venue Owner Email</label>
                                <input class="form-control" placeholder="Email"
                                    value="{{ (venueDetail && venueDetail.user && venueDetail.user.email) ? venueDetail.user.email : 'N/A' }}"
                                    [readonly]="true" />
                            </div>
                            <div class="form-group col-1 user-profile" [style.margin]="'2rem auto'">
                                <p *ngIf="venueDetail && venueDetail.user_id" [style.margin]="'auto'">
                                    <a title="View Profile" class="link-dark"
                                        routerLink="/user/{{venueDetail.user_id}}">
                                        <i class="fa fa-user" aria-hidden="true"></i>
                                    </a>
                                </p>
                            </div>
                        </div>
                    </div>

                    <div class="form-group col-lg-6">
                        <label [ngClass]="{'text-danger': venueDetail && venueDetail?.pause_comment}">Last Pause Reason</label>
                        <textarea class="form-control" placeholder="Comment"
                            value="{{ (venueDetail && venueDetail.pause_comment) ? venueDetail.pause_comment : 'N/A' }}"
                            [readonly]="true"></textarea>
                    </div>

                    <div class="form-group col-lg-6">
                        <fieldset class="border p-2">
                            <legend class="float-none w-auto">Contact Info</legend>
                            <label>Location Name</label>
                            <input type="text" class="form-control" placeholder="Contact Info"
                                value="{{ (venueDetail && venueDetail.contactinfo && venueDetail.contactinfo.name !== '') ? venueDetail.contactinfo.name : 'N/A' }}"
                                [readonly]="true">

                            <label>Address</label>
                            <input type="text" class="form-control" placeholder="Address"
                                value="{{ (venueDetail && venueDetail.contactinfo && venueDetail.contactinfo.address) ? venueDetail.contactinfo.address : 'N/A' }}"
                                [readonly]="true">

                            <label>City </label>
                            <input type="text" class="form-control" placeholder="City"
                                value="{{ (venueDetail && venueDetail.contactinfo && venueDetail.contactinfo.city) ? venueDetail.contactinfo.city : 'N/A' }}"
                                [readonly]="true">

                            <label>State </label>
                            <input type="text" class="form-control" placeholder="State"
                                value="{{ (venueDetail && venueDetail.contactinfo && venueDetail.contactinfo.state) ? venueDetail.contactinfo.state : 'N/A' }}"
                                [readonly]="true">

                            <label>Country </label>
                            <input type="text" class="form-control" placeholder="Country"
                                value="{{ (venueDetail && venueDetail.contactinfo && venueDetail.contactinfo.country) ? venueDetail.contactinfo.country : 'N/A' }}"
                                [readonly]="true">

                            <label>Locality </label>
                            <input type="text" class="form-control" placeholder="Locality"
                                value="{{ (venueDetail && venueDetail.contactinfo && venueDetail.contactinfo.locality) ? venueDetail.contactinfo.locality : 'N/A' }}"
                                [readonly]="true">

                            <label>Phone </label>
                            <input type="text" class="form-control" placeholder="Phone"
                                value="{{ (venueDetail && venueDetail.contactinfo && venueDetail.contactinfo.phonenumber) ? venueDetail.contactinfo.phonenumber : 'N/A' }}"
                                [readonly]="true">

                            <label>Website </label>
                            <input type="text" class="form-control" placeholder="Website"
                                value="{{ (venueDetail && venueDetail.contactinfo && venueDetail.contactinfo.website) ? venueDetail.contactinfo.website : 'N/A' }}"
                                [readonly]="true">

                            <label>Lat-Long </label>
                            <input type="text" class="form-control" placeholder="Lat Long"
                                value="{{ (venueDetail && venueDetail.contactinfo && venueDetail.contactinfo.latlon && venueDetail.contactinfo.latlon.coordinates) ? venueDetail.contactinfo.latlon.coordinates : 'N/A' }}"
                                [readonly]="true">
                        </fieldset>
                    </div>
                </div>
            </div>

            <div class="card mb-6">
                <div class="card-header">Images{{imageArr}}</div>
                <div class="form-row p-3">
                    <ul>
                        <li *ngFor="let image of imageArr" style="display: inline-block!important;padding: 5px;"><img
                                [src]="image" height="100px" width="100px"></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <!-- Update venue status modal -->
    <div id="openVenueModal" class="modal fade addElementModal" tabindex="-1" role="dialog">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{modalTitle}} Venue</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <p> Are you sure you want to {{modalTitle}} the Venue?</p>
                    <div *ngIf="venueStatus == 2 || venueStatus == 5" class="form-group">
                        <label>Enter Reason</label>
                        <textarea rows="4" class="form-control" placeholder="Enter Reason" [(ngModel)]="comment"
                            (ngModelChange)="onReasonTextChange($event)"></textarea>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <button type="button" (click)="changeStatus(selectedVenueId, venueStatus, userEmail)"
                    [disabled]="(venueStatus == 2 || venueStatus == 5) && !comment" [ngClass]="getClassName(venueStatus)">{{modalTitle}}</button>
                </div>
            </div>
        </div>
    </div>
</div>