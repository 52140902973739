import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';

import { VenueSubscriptionService } from '../_services/venue-subscription.service';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
import { VenueSubscription } from '../_models/venue-subscription.model';
import { VENUE_SIZES, VENUE_INTERVAL, STATUS } from '../_constants/venue.constants';
import { Interval } from '../_models/venue-subscription.model';
@Component({
  selector: 'app-venues-subscription',
  templateUrl: './venues-subscription.component.html',
  styleUrls: ['./venues-subscription.component.css']
})
export class VenuesSubscriptionComponent implements OnInit {

  dtOptions: DataTables.Settings = {};
  subscriptions: VenueSubscription[];
  status: any = STATUS;
  qrCodeBaseUrl = `${environment.qrCodeBaseUrl}`;
  venueIntervals: Interval[] = VENUE_INTERVAL;
  constructor(
    private venueSubscriptionService: VenueSubscriptionService,
    private toastr: ToastrService,
    private router: Router) { }

  search_key: string;

  dtTrigger: Subject<any> = new Subject();

  ngOnInit(): void {
    this.loadSubscriptions();
  }
  
  loadSubscriptions() {
    const that = this;
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      serverSide: true,
      processing: true,
      order: [[6,'desc']],
      language: {
        searchPlaceholder: 'Name'
      },
      ajax: (dataTablesParameters: any, callback) => {
        this.search_key = dataTablesParameters.search.value;
        // that.searchFunction(this.search_key);
        this.venueSubscriptionService.subscriptionsListing(dataTablesParameters).
          subscribe(resp => {
            that.subscriptions = resp.data.subscriptions;
            callback({
              recordsTotal: resp.data.total_count,
              recordsFiltered: resp.data.total_count,
              data: []
            });

            // Calling the DT trigger to manually render the table
            // this.dtTrigger.next();
          }, err => {
            callback({
              processing: false,
              recordsTotal: 0,
              recordsFiltered: 0,
              data: []
            });
          });
      },
      columns: [{ data: 'name' }, { data: 'description' }, { data: 'size' }, { data: 'amount' }, { data: 'interval' }, { data: 'status' }, {data: 'noofusers'}]
      // columnDefs: [{ "orderable": false, "targets": 5 }]

    };
  }

  getStatus(id:number) {
    return this.status[id];
  }

  getInterval(val:number) {
    let interval = this.venueIntervals.find(optn => optn.convertedVal == val);
    return interval.value;
  }

  addSubscription() {
    this.router.navigate(['/venue/subscription/add']);
  }
}
