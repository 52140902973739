<div class="container-fluid">

    <!-- DataTales Example -->
    <div class="card shadow mb-4">
        <div class="card-header d-sm-flex align-items-center justify-content-between py-3">
            <h1 class="h3 mb-0 text-gray-800">Account Details</h1>
        </div>
        <div class="card-body">
            <div class="table-responsive">
                <table class="table table-bordered hover" width="100%" cellspacing="0">
                    <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Phone</th>
                        <th></th>
                    </tr>
                    <tr>
                        <td>{{currentUser.name}}</td>
                        <td>{{currentUser.email}}</td>
                        <td>{{currentUser.phone}}</td>
                        <td><a class="btn btn-primary" routerLink="/edit-detail">Edit</a></td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</div>