import { Component, OnInit } from '@angular/core';
import {AuthService} from '../../_services/auth.service';
import {ToastrService} from 'ngx-toastr';
import {Router, ActivatedRoute} from '@angular/router';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  constructor(private authService:AuthService,private toastr:ToastrService,
    private router: Router,
    private route:ActivatedRoute) { }

  ngOnInit(): void {
    // console.log(localStorage);
  }

  logout(){

    this.authService.logout().subscribe(data=>{
      localStorage.removeItem
      ("currentUser");
      this.toastr.success("Logout successfully");
      this.router.navigate(['/login']);
    },err =>{      
      this.toastr.error(err.error.message);
    })
    
  }

}
