import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
export const TOKEN_NAME: string = 'jwt_token';
import { environment } from '../../environments/environment';
import { map, catchError, retry } from 'rxjs/operators';
import {throwError} from 'rxjs';
const AUTH_PREFIX = 'Bearer';

@Injectable({
  providedIn: 'root'
})
export class ChangePasswordService {

  private headers;

  constructor(
    private httpClient:HttpClient
  ) { }

  setHeader(){
    this.headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${AUTH_PREFIX} ${localStorage.getItem(TOKEN_NAME)}`
    });
  }

  change_password(old, new_p){
    this.setHeader();
    return this.httpClient.patch<any>(`${environment.apiUrl}/user/change-password`,{old_password:old, new_password:new_p, confirm_password:new_p}, { headers: this.headers }).
    pipe(retry(0),    
      catchError(err => {
        return throwError(err);
      }));
  }

}
