import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
export const TOKEN_NAME: string = 'jwt_token';
const AUTH_PREFIX = 'Bearer';
import { retry, catchError} from 'rxjs/operators';
import  {throwError} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  private headers:any;
  
  constructor(private httpClient:HttpClient) { }

  setHeader(){
    this.headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${AUTH_PREFIX} ${localStorage.getItem(TOKEN_NAME)}`
    });
  }

  userListing(data){
    this.setHeader();
    var page = 1;
    if (data.start == 0) {

      var page = 1;
    } else {
      var page = (data.start / 10) + 1;
    }

    if (data.order[0].column == 0) {
      var column = "name";
    } else if (data.order[0].column == 1) {
      var column = "email";
    }
    else if (data.order[0].column == 2) {
      var column = "phone_number";
    }
    else if (data.order[0].column == 3) {
      var column = "createdAt";
    } 
    else {
      var column = ""; 
    };

    // console.log(data);
    var orderData = {};
    if (column) {
      orderData = { "column": column, "dir": data.order[0].dir };
    } else {
      orderData = "";
    };

    return this.httpClient.post<any>(`${environment.apiUrl}/user/listing`,
    { "page": page, "limit": data.length, "search_value": new String(data.search.value),"order": orderData },
    { headers: this.headers }
    ).
    pipe(catchError(this.handleError));
  }

  userDetails(userId:string){
    
    this.setHeader();
    return this.httpClient.post<any>(`${environment.apiUrl}/user/get-profile`,{"user_id":userId},
    { headers: this.headers }).
    pipe(retry(1));
  }

  handleError(error: HttpErrorResponse){
    console.log(`Status:${error.status}`);
    return throwError(error);
    }
}
